


export function manageAccess(data) {
    const accessArray = data.access || [];

    let canManageOverview = false;
    let canManageActivityLog = false;
    let canManageLoginUsers = false;
    let canManageSettings = false;

    let canEditChannels = false;
    let canDeleteChannels = false;
    let canSuspendChannels = false;
    let canAddChannels = false;
    let canAddNote = false;


    let canEditUser = false;
    let canDeleteUser = false;
    let canSuspendUser = false;
    let canAddUser = false;


    for (const accessItem of accessArray) {
        // manage overview
        if (accessItem.manage && accessItem.manage.subtype && accessItem.manage.subtype.overview === "1") {
            canManageOverview = true;
        }
        // manage user-logins (user management)
        if (accessItem.manage && accessItem.manage.subtype && accessItem.manage.subtype['user-logins'] === "1") {
            canManageLoginUsers = true;
        }
        // manage activity-log
        if (accessItem.manage && accessItem.manage.subtype && accessItem.manage.subtype['activity-log'] === "1") {
            canManageActivityLog = true;
        }
        //manage settings
        if (accessItem.manage && accessItem.manage.subtype && accessItem.manage.subtype.settings === "1") {
            canManageSettings = true;
        }


        //edit channel
        if (accessItem.edit && accessItem.edit.subtype && accessItem.edit.subtype.channel === "1") {
            canEditChannels = true;
        }
        //delete channel
        if (accessItem.delete && accessItem.delete.subtype && accessItem.delete.subtype.channel === "1") {
            canDeleteChannels = true;
        }
        //suspend channel
        if (accessItem.suspend && accessItem.suspend.subtype && accessItem.suspend.subtype.channel === "1") {
            canSuspendChannels = true;
        }
        //add channel
        if (accessItem.add && accessItem.add.subtype && accessItem.add.subtype.channel === "1") {
            canAddChannels = true;
        }
        //add note
        if (accessItem.add && accessItem.add.subtype && accessItem.add.subtype.note === "1") {
            canAddNote = true;
        }


        //edit user 
        if (accessItem.edit && accessItem.edit.subtype && accessItem.edit.subtype.login === "1") {
            canEditUser = true;
        }
        //delete user
        if (accessItem.delete && accessItem.delete.subtype && accessItem.delete.subtype.login === "1") {
            canDeleteUser = true;
        }
        //suspend user 
        if (accessItem.suspend && accessItem.suspend.subtype && accessItem.suspend.subtype.login === "1") {
            canSuspendUser = true;
        }
        //add user 
        if (accessItem.add && accessItem.add.subtype && accessItem.add.subtype.login === "1") {
            canAddUser = true;
        }
    }

    //left panel
    localStorage.setItem('canManageOverview', canManageOverview);
    localStorage.setItem('canManageActivityLog', canManageActivityLog);
    localStorage.setItem('canManageLoginUsers', canManageLoginUsers);
    localStorage.setItem('canManageSettings', canManageSettings);


    // activity log
    localStorage.setItem('canEditChannels', canEditChannels);
    localStorage.setItem('canDeleteChannels', canDeleteChannels);
    localStorage.setItem('canSuspendChannels', canSuspendChannels);
    localStorage.setItem('canAddChannels', canAddChannels);
    localStorage.setItem('canAddNote', canAddNote);



    //user-login
    localStorage.setItem('canEditUser', canEditUser);
    localStorage.setItem('canDeleteUser', canDeleteUser);
    localStorage.setItem('canSuspendUser', canSuspendUser);
    localStorage.setItem('canAddUser', canAddUser);

}
