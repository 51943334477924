import React from "react";
import { NavItem } from "./components";
import { useRoutes } from "hookrouter";
import { Troops } from "./overview";
import { Transactions } from "./events";
import { Payers } from "./settings";
import { AnimatePresence, motion } from "framer-motion";
import {
  Logout,
} from "heroicons-react";
import { logout } from "../utils/fetch";
import Plans from "./users";
import { IconEnroll, IconSetting, IconOverview, IconClock } from "../assets/icons/hiking";
import "react-toastify/dist/ReactToastify.css";
import { usePanelStyle } from './PanelStyle';


const routes = {
  "/": () => <Troops />,

  "/overview": () => localStorage.getItem('canManageOverview') === "true" ? <Troops /> : <div />,
  "/tam-events": () => localStorage.getItem('canManageActivityLog') === "true" ? < Transactions /> : <div />,
  "/enroll": () => localStorage.getItem('canManageLoginUsers') === "true" ? <Plans /> : <div />,
  "/settings": () => localStorage.getItem('canManageSettings') === "true" ? < Payers /> : <div />,

};


export default function Main() {
  const routeResult = useRoutes(routes);

  const { panelStyle } = usePanelStyle();
  const imageStyle = {
    transform: `rotate(${panelStyle.imageRotation}deg)`,
  };

  const textClass = `${panelStyle.showText ? '!text-white' : 'hidden'}`;

  const panelClass = `${panelStyle.panelWidth} h-full text-white rounded-lg bg-firefly`;
  const imageClass = `${panelStyle.imageWidth} ${panelStyle.imageMarginBottom}`;



  return (
    <div className="flex w-full h-screen px-4 py-4 mx-auto">
      <div className="fixed h-full pr-10 pb-6">
        <div className={`flex flex-col justify-between ${panelClass}`}>
          <div className="flex flex-col items-center p-2 pt-6 pb-6">
            <img className={imageClass} src={`${process.env.PUBLIC_URL}/TRC_4r-logo_white.webp`} style={imageStyle} />
            <p className="text-xs italic text-red">{localStorage.getItem('username')} </p>
            <div className={`px-1 py-0.5 bg-red-600 text-sm text-white font-semibold cursor-default ${textClass}`}>
              Activity Monitor
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-center mb-60">
            <motion.ul className="flex flex-col">

              {localStorage.getItem('canManageOverview') === "true" && (
                <NavItem linkTo="/app/overview" selected={routeResult.type === Troops}>
                  <IconOverview className="-ml-5 justify-center mr-2" /><span className={textClass}>Overview</span>
                </NavItem>
              )}

              {localStorage.getItem('canManageActivityLog') === "true" && (
                <NavItem linkTo="/app/tam-events" selected={routeResult.type === Transactions}>
                  <IconClock className="-ml-5 mr-2" /><span className={textClass}>Activity Log</span>
                </NavItem>
              )}

              {localStorage.getItem('canManageLoginUsers') === "true" && (
                <NavItem linkTo="/app/enroll" selected={routeResult.type === Plans}>
                  <IconEnroll className="-ml-5 mr-2" /><span className={textClass}>User Management</span>
                </NavItem>
              )}

              {localStorage.getItem('canManageSettings') === "true" && (
                <NavItem linkTo="/app/settings" selected={routeResult.type === Payers}>
                  <IconSetting className="-ml-5 mr-2" /><span className={textClass}>Settings</span>
                </NavItem>
              )}
            </motion.ul>
          </div>

          <div className="p-6 text-center">
            <div onClick={logout} className="flex justify-center cursor-pointer">
              <Logout className="pr-2" /><span className={textClass}>Logout</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-4 text-gray-800" style={{ marginLeft: panelStyle.contentMarginLeft, paddingTop: "0" }}>
        <AnimatePresence exitBeforeEnter>
          {routeResult || <div></div>}
        </AnimatePresence>
      </div>
    </div >
  );

}




