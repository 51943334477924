import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { useNotifications } from './NotificationContext';
import moment from 'moment-timezone';

export function Troops() {
  const [ipAccessData, setIpAccessData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);

    const type = "wysiwyg";
    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }
    const url = protocolpluspath() + 'admin/getData.php?type=' + type;
    fetch(url)
      .then(response => {
        console.log('Response received');
        if (!response.ok) {
          throw new Error('The network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data:', data);
        if (data && data.data && data.data.success && Array.isArray(data.data.response)) {
          const filteredData = data.data.response.filter(item => item.id === 0);
          if (filteredData.length > 0) {
            const wysiwygData = filteredData.map(item => ({
              text: item.wysiwyg.wysiwyg_text,
              type: item.wysiwyg.wysiwyg_type
            }));
            setIpAccessData(wysiwygData);
          }
        }
        else {
          throw new Error('No data returned or the operation was unsuccessful');
        }
      })
      .catch(error => {
        console.error('Error processing data:', error);
      })
      .finally(() => setIsLoading(false));
  }, []);



  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "activity";
    const filter = "TAM-Events";

    function protocolpluspath() {
      return window.location.protocol + '//' + window.location.host + '/';
    }

    let url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&search=' + '&filter=' + filter;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log('Data fetched:', data);
        if (data.success && data.data && Array.isArray(data.data.response)) {
          countRecentEvents(data.data.response);
        } else {
          console.error('Unexpected data structure:', data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);











  const [channels, setChannels] = useState([]);
  const [channelCount, setChannelCount] = useState(0);

  useEffect(() => {
    const eid = localStorage.getItem('default');
    const type = "channels_enum";
    const url = `${window.location.protocol}//${window.location.host}/admin/getData.php?eid=${eid}&type=${type}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const filteredChannels = data.data.response.filter(channel => channel !== "Select");
        setChannels(filteredChannels);
        setChannelCount(filteredChannels.length);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }, []);

  function InfoBox({ title, number, icon }) {
    return (
      <div className="box-content relative p-4">
        <h2 className="absolute top-4 left-4">{title}</h2>
        {icon && <div className="absolute top-4 right-4">{icon}</div>}
        <div className="box-overview-number absolute bottom-4 left-4 text-xl">{number}</div>
      </div>
    );
  }

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const { showNotification } = useNotifications();


  const [showPopup, setShowPopup] = useState(false);


  //logic handles the last hour events

  const [recentEventCount, setRecentEventCount] = useState(0);

  function countRecentEvents(events) {
    const now = moment(); // Hora actual en la zona horaria del usuario
    const oneHourAgo = now.clone().subtract(1, 'hours'); // Una hora atrás en la zona horaria del usuario

    let recentEventCount = 0;

    events.forEach(event => {
      const eventDate = moment.utc(event.tam_event.created_date); // Fecha del evento en UTC
      if (eventDate.isBetween(oneHourAgo, now)) {
        recentEventCount++;
      }
    });

    setRecentEventCount(recentEventCount); // Actualiza el estado con el conteo de eventos recientes
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex-parent flex justify-between px-3 ">
        <div>
          <div className="flex-parent flex justify-between px-3 ">
            <h1 className="overview-title">Welcome back,  {localStorage.getItem('username')}!</h1>
            {/* <button onClick={showNotification}>Show notification</button> */}
          </div>
          <div className="flex justify-between mt-4 py-2">
            <InfoBox title="Events: Last Hour" number={isLoading ? '...' : recentEventCount} className="ml-1 mr-1" />
            <InfoBox
              title="Total Channels"
              number={<span className="underline cursor-pointer" onClick={() => setShowPopup(true)}>{isLoading ? '...' : channelCount}</span>}
              className="ml-1 mr-1"
            />
            {/* <InfoBox title="Issues" number="03"  /> */}
            {showPopup && (
              <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-8 rounded-lg max-w-4xl w-full">
                  <h2 className="text-3xl font-bold mb-4">Channel List</h2>
                  <ul className="list-disc pl-5 mb-8">
                    {channels.map((channel, index) => (
                      <li key={index}>{channel}</li>
                    ))}
                  </ul>
                  <div className="flex justify-end">
                    <button onClick={() => setShowPopup(false)} className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700">Close</button>
                  </div>
                </div>
              </div>
            )}

          </div>
          <div className="text-overview-container">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ul>
                {ipAccessData.map((item, index) => (
                  <li key={index}>
                    <div className="unstyled-content">
                      <div dangerouslySetInnerHTML={{ __html: decodeHtml(item.text) }} />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </motion.div >
  );
}
