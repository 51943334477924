import React from "react";
import { A } from "hookrouter";
import { motion } from "framer-motion";


export function NavItem({ children, selected, linkTo = "" }) {
  return (
    <motion.li animate className={`py-1.5 flex relative ${selected && "text-redshade text-shadow-inset"}`}>
      <motion.div
        animate
        className={`absolute left-0 top-0 bottom-0 w-1 ${selected && "bg-redshade"} rounded-r`}
      />
      <A href={linkTo} className="pl-12 text-base font-semibold flex items-center relative z-10">
        {
          React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                fillColor: selected ? "#EC2842" : "white",
                strokeColor: selected ? "#EC2842" : "white"
              });
            }
            return child;
          })
        }
      </A>
    </motion.li>
  );
}